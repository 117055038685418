import React, { useEffect } from 'react'

export default function Logout() {
  useEffect(() => {
    localStorage.removeItem('token')
    location.href = '/' + location.search
  })

  return <React.Fragment />
}
